var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promote"},[_c('van-nav-bar',{attrs:{"title":_vm.tyleTile?_vm.tyleTile:_vm.capitalizeFirstLetter,"left-arrow":"","fixed":"","placeholder":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"WQRH7kQFHu0625bXlsSq"},[_c('div',{staticClass:"_PUxRK2xxVs7RWk3be3YQ"},[_c('searchFor',{attrs:{"vModel":_vm.formNameConye,"placeholder":"Pesquisar","className":"search-bac-s"},on:{"input":_vm.search_Game}})],1)]),_c('div',{staticClass:"tab-search"},[_c('div',{staticClass:"tab-box"},[(_vm._isvanLoading && !_vm.tabList.length)?_c('div',{staticStyle:{"width":"100%","height":"calc(100vh - 3.7rem)","display":"flex","justify-content":"center","align-items":"center"}},[_c('sLoading')],1):_c('div',_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"FhgIcPultEE23ZJcpVgx",class:{
					active:_vm.tabActive==index
				},style:({
					 'background-image':`url(${_vm.tabActive==index?_vm.btn_zc2_1:_vm.btn_zc2_2})`,
					 'background-size':'100% 100%',
				 }),on:{"click":function($event){return _vm.tabLeft(index)}}},[_c('div',{staticClass:"N4LsWhJBhqqyTkv6V0NE search-tab"},[(item.key && _vm.tyleTile)?_c('svg-icon',{staticStyle:{"width":".6rem","height":".6rem"},attrs:{"className":"imageNav-a","icon-class":"icon_dtfl_by_0--svgSprite:all"}}):(item.key &&item.name!='Demo')?_c('svg-icon',{staticStyle:{"width":".6rem","height":".6rem"},attrs:{"className":"imageNav-a","icon-class":"icon_dtfl_dz_0--svgSprite:all"}}):_vm._e(),(item.key &&item.name=='Demo')?_c('svg-icon',{staticStyle:{"width":".6rem","height":".6rem"},attrs:{"className":"imageNav-a","icon-class":"icon_dtfl_sw_0--svgSprite:all"}}):(!item.key)?_c('img',{staticClass:"imageWrap",attrs:{"src":item.icon,"alt":""}}):_vm._e(),_c('span',{staticClass:"tie"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),0)]),_c('div',{staticClass:"tab-conter"},[_c('div',{staticClass:"tab-fenlei"},[(_vm.category!='demo')?_c('ul',[(_vm.formNameConye)?_c('div',{staticClass:"list",class:{
							active:_vm.tabCAcvti==-2
						},on:{"click":function($event){return _vm.tabCAcvtiFun(-2)}}},[_vm._v(" Pesquisar ")]):_vm._e(),(_vm.tabList[_vm.tabActive].key!=true)?_c('div',{staticClass:"list",class:{
						active:_vm.tabCAcvti==-1
					},on:{"click":function($event){return _vm.tabCAcvtiFun(-1)}}},[_vm._v(" Tudo ")]):_vm._e(),_vm._l((_vm.platform),function(item,index){return _c('div',{key:index,staticClass:"list",class:{
						active:_vm.tabCAcvti==index
					},on:{"click":function($event){return _vm.tabCAcvtiFun(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2):_c('ul',[(_vm.formNameConye)?_c('div',{staticClass:"list",class:{
							active:_vm.tabCAcvti==-2
						},on:{"click":function($event){return _vm.tabCAcvtiFun(-2)}}},[_vm._v(" Pesquisar ")]):_vm._e(),_c('div',{staticClass:"list",class:{
						active:_vm.tabCAcvti==-1
					},on:{"click":function($event){return _vm.tabCAcvtiFun(-1)}}},[_vm._v(" Tudo ")]),_c('div',{staticClass:"list",class:{
						active:_vm.tabCAcvti==0
					},on:{"click":function($event){_vm.typeDemo='slot',_vm.tabCAcvtiFun(0)}}},[_vm._v(" Slots ")])])]),_c('div',{staticClass:"subgame",class:_vm.config.ui_version!='3.5'?'search-gameItemBox':''},[(_vm._isvanLoading)?_c('div',{staticStyle:{"width":"100%","height":"calc(100vh - 3.7rem)","display":"flex","justify-content":"center","align-items":"center"}},[_c('sLoading')],1):_c('div',{staticClass:"gameItemBox",staticStyle:{"width":"100%"}},[_vm._l((_vm.tabItemConter.list),function(item_a,index_1){return (_vm.config.ui_version=='3.5')?_c('gameItem3Dian5',{key:index_1,staticStyle:{"--cardRowSpacing":"0.22rem","--cardColSpacing":"0.065rem"},attrs:{"item":{...item_a,type:'game',category:_vm.category}}}):_vm._l((_vm.tabItemConter.list),function(item_a,index_1){return _c('gameItem',{key:index_1,attrs:{"item":{...item_a,type:'game',category:_vm.category}}})})}),(!_vm.tabItemConter.count)?_c('div',{staticStyle:{"height":"calc(100vh - 3.7rem)","display":"flex","justify-content":"center","align-items":"center"}},[_c('van-empty',{staticClass:"custom-image",staticStyle:{"text-align":"center"},attrs:{"image-size":"2.1rem","image":_vm.img_none_sj,"description":"Nenhum jogo disponível"}})],1):_vm._e()],2)]),(_vm.tabItemConter.count)?_c('div',{staticClass:"v-iyst"},[_c('van-pagination',{attrs:{"items-per-page":"30","total-items":_vm.tabItemConter.count,"show-page-size":5},on:{"change":_vm.funChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow"}})]},proxy:true},{key:"page",fn:function({ text }){return [_vm._v(_vm._s(text))]}}],null,false,3075570953),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }