<template>
	<div class="promote">
		<van-nav-bar :title="tyleTile?tyleTile:capitalizeFirstLetter" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="WQRH7kQFHu0625bXlsSq">
			<div class="_PUxRK2xxVs7RWk3be3YQ">
				<searchFor :vModel="formNameConye"  placeholder="Pesquisar"      @input="search_Game" className="search-bac-s"></searchFor>
			</div>
		</div>
		<div class="tab-search">
			<div class="tab-box">
				<div v-if="_isvanLoading && !tabList.length" style="width: 100%;height: calc(100vh - 3.7rem);display: flex;justify-content: center;align-items: center;">
					<sLoading/>
				</div>
				<div v-else>
					<!-- tyleTile -->
					<!-- #icon_dtfl_by_0--svgSprite:all -->
					<div  class="FhgIcPultEE23ZJcpVgx" :class="{
						active:tabActive==index
					}"
					 :style="{
						 'background-image':`url(${tabActive==index?btn_zc2_1:btn_zc2_2})`,
						 'background-size':'100% 100%',
					 }"
					 @click="tabLeft(index)" v-for="(item,index) in tabList" :key="index">
						<div class="N4LsWhJBhqqyTkv6V0NE search-tab " >
							<svg-icon v-if="item.key && tyleTile" className="imageNav-a" style="width: .6rem;height: .6rem;" icon-class="icon_dtfl_by_0--svgSprite:all" />
							<svg-icon v-else-if="item.key &&item.name!='Demo'" className="imageNav-a" style="width: .6rem;height: .6rem;" icon-class="icon_dtfl_dz_0--svgSprite:all" />
							<svg-icon v-if="item.key &&item.name=='Demo'" className="imageNav-a" style="width: .6rem;height: .6rem;" icon-class="icon_dtfl_sw_0--svgSprite:all" />
							<img v-else-if="!item.key" class="imageWrap"  :src="item.icon" alt="">
							<span class="tie">
								{{item.name}}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-conter" >
				<div class="tab-fenlei">
					<ul v-if="category!='demo'">
						<div class="list"
							v-if="formNameConye"
							@click="tabCAcvtiFun(-2)"
							:class="{
								active:tabCAcvti==-2
							}"
						>
							Pesquisar
						</div>
						<div class="list" 
						@click="tabCAcvtiFun(-1)"
						:class="{
							active:tabCAcvti==-1
						}"	 v-if="tabList[tabActive].key!=true" >
							Tudo
						</div>
						<div class="list"
						 @click="tabCAcvtiFun(index)"
						 :class="{
							active:tabCAcvti==index
						}"	 v-for="(item,index) in platform"  :key="index">
							{{item.name}}
						</div>
					</ul>
					<ul v-else>
						<div class="list"
							v-if="formNameConye"
							@click="tabCAcvtiFun(-2)"
							:class="{
								active:tabCAcvti==-2
							}"
						>
							Pesquisar
						</div>
						<div class="list"
						@click="tabCAcvtiFun(-1)"
						:class="{
							active:tabCAcvti==-1
						}">
							Tudo
						</div>
						<div class="list"
						@click="typeDemo='slot',tabCAcvtiFun(0)"
						:class="{
							active:tabCAcvti==0
						}">
							Slots
						</div>
						
					</ul>
				</div>
				<div class="subgame" :class="config.ui_version!='3.5'?'search-gameItemBox':''">
					<div v-if="_isvanLoading" style="width: 100%;height: calc(100vh - 3.7rem);display: flex;justify-content: center;align-items: center;">
						<sLoading/>
					</div>
				  <div v-else style="width: 100%;" class="gameItemBox">
					  <gameItem3Dian5  style="--cardRowSpacing: 0.22rem;--cardColSpacing: 0.065rem;" v-if="config.ui_version=='3.5'" v-for="(item_a,index_1) in tabItemConter.list" :item="{...item_a,type:'game',category:category}" :key="index_1"></gameItem3Dian5>
					  <gameItem v-else  v-for="(item_a,index_1) in tabItemConter.list" :item="{...item_a,type:'game',category:category}" :key="index_1"></gameItem>
					  <div v-if="!tabItemConter.count" style="height: calc(100vh - 3.7rem);display: flex;justify-content: center;align-items: center;">
						  <van-empty
						      image-size="2.1rem"
						  	  style="text-align: center;"
						      class="custom-image"
						      :image="img_none_sj"
						      description="Nenhum jogo disponível"
						  />
					  </div>
				  </div>
				  
				</div>
				<div class="v-iyst" v-if="tabItemConter.count">
					<van-pagination v-model="currentPage" @change="funChange" items-per-page="30" :total-items="tabItemConter.count" :show-page-size="5">
					  <template #prev-text>
					    <van-icon name="arrow-left" />
					  </template>
					  <template #next-text>
					    <van-icon name="arrow" />
					  </template>
					  <template #page="{ text }">{{ text }}</template>
					</van-pagination>
				</div>
			
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import searchFor from "@/components/searchFor/searchFor.vue";
	import {ICON_search} from "@/assets/public/icon_nav.js"
	import gameItem from "@/components/gameItem/index.vue"
	import gameItem3Dian5 from "@/components/gameItem/index3.5.vue"
	import { mapState } from 'vuex';
	
	import {getPlatformByGameCategory,getGameList,searchGame} from "@/apis/modules/subgame.js"
	export default {
		components: {
			searchFor,
			gameItem,
			gameItem3Dian5
		},
		data() {
			return {
				tabActive:0,
				_isvanLoading:false,
				tabCAcvti:-1,
				activeKey:0,
				currentPage:1,
				
				activeLength:'9',
				ICON_search:ICON_search,
				// slot/piscator/demo
				tabList:[
				],
				platformValue:"",
				platform:[
					{name:'Popular',code:'popular'},
					{name:'Recente',code:'recent'},
					{name:'Favoritos',code:'favorite'},
				],
				//all
				platformDom:[
					
				],
				tabItemConter:{
					
				},
				formNameConye:'',
				tabNavItem:[],
				_code:'',
				category:'slot',
				typeDemo:"all",
				tyleTile:"",
				// Pesquisar
			}
		},
		created() {
				let {code,category}=this.$route.query;
				
				if(category && category!='demo'){
					this.category=category;
					this._code=code;
				}
				if(category && category=='demo'){
					this.platformValue="all"
					this.category=category;
				}
				if(category=='piscator'){
					this.tyleTile='Pescaria'
				}
				// icon_dtfl_sw_0--svgSprite:all
				this.getPlatformByGameCategory();
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config']),
			capitalizeFirstLetter() {
			 let string=this.category
			  return string.charAt(0).toUpperCase() + string.slice(1);
			}
		},
		methods: {
			tabCAcvtiFun(index){
				this.tabCAcvti=index;
				if(this.category!="demo"){
					this.tabActive=0;
					this.platformValue=this.platform[index].code;
				}
				this.tabItemConter={};
				this.getGameList();
				// if(this.tabCAcvti==-1){
				// 	this.search_Game();
				// 	return false;
				// }
				// this.getGameList();
			},
			tabLeft(index){
				this.tabActive=index;
				if(index==0){
					this.tabCAcvti=0;
					this.tabItemConter={};
					this.getGameList();
					return false;
				}
				this.platformValue=this.tabList[this.tabActive].code;
				this.tabCAcvti=-1;
				this.getGameList();
			},
			getPlatformByGameCategory(){
					this._isvanLoading=true;
					getPlatformByGameCategory({
						category:this.category,
					}).then(({data})=>{
						this.tabList=[{
						name:this.tyleTile?this.tyleTile:this.category=='demo'?'Demo':'Slots',
						key:true,
					},...data];
					data.forEach((item,index)=>{
						if(this._code==item.code){
							this.tabActive=index+1
						}
					})
						this._isvanLoading=false;
						if(data.length){
							this.getGameList();
							this.currentPage=1;
						}
					})
			},
			funChange(){
				this.getGameList()
			},
			getGameList(el){
				this._isvanLoading=true;
				this.formNameConye='';
				getGameList({
					category:this.category,
					platform:this.platformValue || this.tabList[this.tabActive].code,
					page:this.currentPage,
					type:this.category=='demo'?this.typeDemo:"",
				}).then(({data})=>{
					this._isvanLoading=false;
					this.tabItemConter=data;
				})
			},
			search_Game(e){
				
				this._isvanLoading=true;
				this.tabCAcvti=-2
				this.tabActive=0;
				if(e){
					this.formNameConye=e?e.target.value:'';
				}
				
				if(!this.formNameConye){
					this.tabCAcvti=-1
					this.tabActive=1;
					this.getGameList()
				}
				if(!this.formNameConye){
					return false;
				}
				searchGame({
					category:'search',
					name:this.formNameConye,
					size:9999
				}).then(({code,mas,data})=>{
					if(data.list){
						this._isvanLoading=false;
						this.tabItemConter=data;
					}else{
						this.tabItemConter={};
					}
				})
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	}
</script>
<style>
	.search-bac-s .ant-input {
		background-color: transparent !important;
	}
	.subgame .game_img{
			margin:0.1rem 0 !important;
			/* height: 100% !important; */
			/* width: 100% !important; */
			--card-benchmark:1.6rem !important;
	}
	.search-gameItemBox.subgame .game_item{
		--card-benchmark:1.6rem !important;
		--cardRowSpacing:.1rem !important;
		--cardRowSpacing: 0.1rem !important;
		/* margin: .1rem 0 !important; */
	}
	.subgame .gameItemBox{
		height: auto;
	}
	.v-iyst{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: .3rem;
	}
	.v-iyst .van-pagination__item{
		    border-radius: .1rem;
		    font-size: .2rem;
		    height: .5rem;
			max-width: .5rem !important;
			min-width: .5rem !important;
			padding: 0 !important;
			color: var(--theme-text-color-darken);
			border: 1px solid var(--theme-color-line);
		    line-height: .5rem;
			background-color: var(--theme-main-bg-color);
		    margin: 0 .1rem 0 0;
		    width: .5rem;
	}
	.v-iyst .van-pagination__item--active{
		    border-color: var(--theme-primary-color) !important;
			color: var(--theme-primary-font-color);
			background-color: var(--theme-primary-color);
	}
	.v-iyst .van-pagination__item::after{
		border: none !important;
	}
	.v-iyst .van-pagination__item .van-icon-arrow-left{
		color: var(--theme-primary-color);
	}
	.v-iyst .van-pagination__item .van-icon-arrow{
		color: var(--theme-primary-color);
	}
	.v-iyst .van-pagination__item--disabled .van-icon-arrow{
		color: var(--theme-color-line) !important;
		opacity: 1;
	}
	.v-iyst .van-pagination__item--disabled {
		opacity: 1 !important;
	}
	.v-iyst .van-pagination__item--disabled .van-icon-arrow-left{
		color: var(--theme-color-line) !important;
	}
</style>
<style scoped>
	.subgameAls{
		display: none;
	}
	.WQRH7kQFHu0625bXlsSq {
		padding:.2rem;
	}
	.subgame{
		padding-right: .15rem;
		overflow: scroll;
		height: calc(100vh - 3.7rem);
	}
	.tab-box{
		width: 1.5rem;
		min-width: 1.5rem;
		position: relative;
		height: 100%;
		overflow: scroll;
	}
	.subgame::-webkit-scrollbar ,.tab-box::-webkit-scrollbar {
	  display: none; /* 针对Webkit浏览器，如Chrome、Safari */
	}
	.subgame,.tab-box {
	  -ms-overflow-style: none; /* 针对IE、Edge浏览器 */
	  scrollbar-width: none; /* 针对Firefox浏览器 */
	}
	.tab-box ul {
		margin: 0;
		padding: 0;
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 0;
		unicode-bidi: isolate;
	}
	.tab-conter{
		    -ms-flex-positive: 1;
		    flex-grow: 1;
		    padding-left: .2rem;
		    position: relative;
			box-sizing: border-box;
		    width: -webkit-fit-content;
		    width: -moz-fit-content;
		    width: fit-content;
	}
	.FhgIcPultEE23ZJcpVgx {
	    color: var(--theme-text-color);
	    cursor: pointer;
	    height: 1.05rem;
		/* margin: .2rem 0; */
		margin-bottom: .2rem;
	    position: relative;
		display: flex;
		align-items: center;
	    width: 1.5rem;
	    z-index: 0;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .18rem;
		-webkit-box-shadow: 0 .02rem .04rem 0 var(--theme-aside-no-active-box-shadow);
		box-shadow: 0 .02rem .04rem 0 var(--theme-aside-no-active-box-shadow);
	}
	.active{
		background-color: var(--theme-primary-color) !important;
		    border: initial;
		    color: var(--theme-primary-font-color) !important;
	}
	.search-tab{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding: .1rem;
	}
	.search-tab .imageNav-a ,.search-tab .imageWrap{
		width: 0.52rem;
		margin: 0 auto;
		height: 0.52rem;
	}
	.tab-fenlei{
		 height: .55rem;
		 width: 5.3rem;
		overflow-x: scroll;
		margin-bottom: .2rem;
	}
	.tab-fenlei::-webkit-scrollbar {
	  display: none; /* 针对Webkit浏览器，如Chrome、Safari */
	}
	.tab-fenlei {
	  -ms-overflow-style: none; /* 针对IE、Edge浏览器 */
	  scrollbar-width: none; /* 针对Firefox浏览器 */
	}
	.tab-fenlei ul{
		height: .55rem;
		display: flex;
		flex: 1;
	}
	.tab-fenlei .list{
		border-radius: .14rem;
		width: 1.4rem;
		min-width: 1.4rem;
		scrollbar-width: none;
		    font-size: .22rem;
		text-align: center;
		line-height: 0.52rem;
		height: 100%;
		 color: var(--theme-text-color);
		margin-right: .2rem;
		/* display: inline-block; */
				background-color: var(--theme-main-bg-color);
		    border: .01rem solid var(--theme-color-line);
	}
	.search-tab .tie{
		width: 100%;
		 -webkit-line-clamp: 2;
		    -webkit-box-orient: vertical;
		    display: -webkit-box;
		    line-height: 1.2;
		    overflow: hidden;
			font-size: .24rem;
		    overflow: initial;
		    text-align: center;
		    text-overflow: ellipsis;
		    vertical-align: middle;
		    word-break: break-word
	}
	.search-active-name{
		display: flex;
		font-size: .24rem;
		font-weight: 400;
		align-items: center;
	}
	.search-active-name img{
		width: .38rem;
		height: .38rem;
		margin-right: .1rem;
	}

	.search-gameItemBox{
		/* padding-top: .2rem; */
	}
	


	.KPde_9JUTJjmM6WyyeRA li svg {
		position: absolute;
		width: .12rem;
		height: .12rem;
		font-size: .12rem;
		color: #fff;
		right: -.1rem;
		background: var(--theme-text-color-placeholder);
		border-radius: 50%;
		top: -.1rem;
		border: .07rem solid var(--theme-text-color-placeholder);
		/* width: .26rem; */
	}
	.tab-search{
		margin-top: 0;
		padding: 0 .2rem;
		display: flex;
		height: calc(100vh - 2rem);
		justify-content: space-between;
	}
</style>
