<template>
	<div>
		<van-row class="tenConter _border">
		    <van-col class="tr" span="4">{{ $t('_VIP._Nível') }}</van-col>
		    <van-col class="tr _disps" span="11">
		        {{ $t('_VIP._Apostaparapromoção') }}
		        <span class="ts" @click="$emit('update:Nvel_ss',true)">
		            <svg-icon className="imageWrap"  icon-class="comm_icon_wh--svgSprite:all"  />
		        </span>
		    </van-col>
		    <van-col class="tr" span="10">{{ $t('_VIP._BônusDeAumentoDe') }}</br> {{ $t('_VIP._Nível') }}</van-col>
		</van-row>
		<div class="tenConter-table-body">
			<!-- formDelList.level -->
				<van-row  :class="(index%2==0?'tenConter tc_sae':'tenConter')" :style="{
					border:formDelList.level==item.level?'1px solid var(--theme-primary-color)':''
				}" v-for="(item,index) in formDelList.list" :key="index">
					<van-col class="tr" span="4">
						<div class="JlCRc6ttKGcNUyDwFygw reward-mixins__levelImage l2ogUtOtFSdH0ER0ochY" style="flex: 0 0 0.7rem; width: 0.7rem; height: 0.7rem; font-size: 0.254545rem;">
							<img  v-lazy="item.backgroud"  class="goDRiiBsuEuXD3W1NphN" style="width: 0.7rem; height: 0.7rem;">
							<img v-lazy="item.icon"  class="goDRiiBsuEuXD3W1NphN" style="width: 0.7rem; height: 0.7rem;">
							<span :data-text="item.level">{{item.level }}</span>
						</div>
					</van-col>
					<van-col class="tr JIndut" span="10">
						<div>
							<span class="RL9TJMMzDMOQihFhVnG2"> {{item.upwhere_bet}}</span>
							<div v-if="formDelList.level+1==index" class="_2lvGimPeAnoEFiUw_fTw zWM93XVBJEk4aHJfKHVR">
								<div  class="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default">
									<div class="ant-progress-outer">
										<div class="ant-progress-inner">
											<div class="ant-progress-bg" :style="{
												width: `${(removeThousandSeparatorAndDecimalPoint(formDelList.now_bet)/removeThousandSeparatorAndDecimalPoint(item.upwhere_bet))*100}%`,
												height: '20px', 
												borderRadius:'100px',
												    'background-color': 'var(--theme-secondary-color-success)'
											}"></div>
											<!-- {{removeThousandSeparatorAndDecimalPoint(formDelList.now_bet)}} -->
										</div>
									</div>
								</div>
								<span class="reward-mixins__amount">{{formDelList.now_bet}}/ {{item.upwhere_bet}}</span>
							</div>
						</div>
					</van-col>
					<van-col class="tr" span="10">
						<span class="RL9TJMMzDMOQihFhVnG2 lJM4_qheVPhMhtExmFdS">{{item.upgrade_bonus}}</span>
					</van-col>
			</van-row>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: [Array,Object],
				required: [],
			},
			formDelList:{
				type: [Array,Object],
				default: ()=>{return {}},
			},
			Nvel_ss:{
				type: Boolean,
				required: false,
			}
		},
		data() {
			return {
			};
		},
		methods: {
			CurrencyFomatNumber(number, n) {
			  if (number != null && number != '' && number != undefined) {
				if(typeof  number == "number"){
					return number;
				}else{
				   let  conter=number.split('.');
				   conter.pop();
				   let _s=''
				   conter.map(item=>{
					 _s=_s+item;
				   })
				   return  _s					   
				}
				
			  } else {
				number = '0';
			  }
			  return number;
			},
			onChange(e) {
			}
		}
	};
	
</script>

<style scoped>




	.JlCRc6ttKGcNUyDwFygw img,.NPzDoGC69c0JdVxoaQso img,.l2ogUtOtFSdH0ER0ochY img {
	    position: absolute
	}
	.JlCRc6ttKGcNUyDwFygw span:before,.NPzDoGC69c0JdVxoaQso span:before,.l2ogUtOtFSdH0ER0ochY span:before {
	    -webkit-background-clip: text;
	    background-clip: text;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f7ea94),color-stop(51%,#e5b952),to(#ce9510));
	    background-image: linear-gradient(180deg,#f7ea94 0,#e5b952 51%,#ce9510);
	    background-size: cover;
	    content: attr(data-text);
	    height: 100%;
	    left: 0;
	    position: absolute;
	    text-shadow: none;
	    top: 0;
	    width: 100%;
	    z-index: 0
	}
	.l2ogUtOtFSdH0ER0ochY {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    background-position: 50%;
	    background-repeat: no-repeat;
	    background-size: contain;
	    display: flex;
	    justify-content: center;
	    margin: auto;
	    position: relative;
	    text-align: center;
	}
	.JlCRc6ttKGcNUyDwFygw span,.NPzDoGC69c0JdVxoaQso span,.l2ogUtOtFSdH0ER0ochY span {
	    color: transparent;
	    display: inline-block;
	    font-style: normal;
	    font-weight: 700;
	    position: relative;
	    text-shadow: 0 .01rem 0 rgba(0,0,0,.4)
	}
	 ._2lvGimPeAnoEFiUw_fTw {
	    position: relative;
	    width: 3.1rem;
	    margin-top: .1rem;
	}
	 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner {
	    background-color: var(--theme-text-color-placeholder);
		overflow: hidden;
	    border-radius: .2rem
	}
	 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner .ant-progress-bg {
	    background-color: var(--theme-vip-progress-bg-color);
	    border-radius: .2rem !important;
	    height: .2rem !important;
	}
	._2lvGimPeAnoEFiUw_fTw>span {
	    -webkit-background-clip: text!important;
	    color: #fff;
	    font-size: .16rem;
	    left: 50%;
	    overflow: hidden;
	    position: absolute;
	    text-align: center;
	    text-overflow: ellipsis;
	    top: 60%;
	    transform: translate(-50%,-58%);
	    white-space: nowrap;
	    width: 94%
		}

</style>