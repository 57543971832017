var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-row',{staticClass:"tenConter _border"},[_c('van-col',{staticClass:"tr",attrs:{"span":"4"}},[_vm._v(_vm._s(_vm.$t('_VIP._Nível')))]),_c('van-col',{staticClass:"tr _disps",attrs:{"span":"11"}},[_vm._v(" "+_vm._s(_vm.$t('_VIP._Apostaparapromoção'))+" "),_c('span',{staticClass:"ts",on:{"click":function($event){return _vm.$emit('update:Nvel_ss',true)}}},[_c('svg-icon',{attrs:{"className":"imageWrap","icon-class":"comm_icon_wh--svgSprite:all"}})],1)]),_c('van-col',{staticClass:"tr",attrs:{"span":"10"}},[_vm._v(_vm._s(_vm.$t('_VIP._BônusDeAumentoDe'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('_VIP._Nível')))])],1),_c('div',{staticClass:"tenConter-table-body"},_vm._l((_vm.formDelList.list),function(item,index){return _c('van-row',{key:index,class:(index%2==0?'tenConter tc_sae':'tenConter'),style:({
				border:_vm.formDelList.level==item.level?'1px solid var(--theme-primary-color)':''
			})},[_c('van-col',{staticClass:"tr",attrs:{"span":"4"}},[_c('div',{staticClass:"JlCRc6ttKGcNUyDwFygw reward-mixins__levelImage l2ogUtOtFSdH0ER0ochY",staticStyle:{"flex":"0 0 0.7rem","width":"0.7rem","height":"0.7rem","font-size":"0.254545rem"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.backgroud),expression:"item.backgroud"}],staticClass:"goDRiiBsuEuXD3W1NphN",staticStyle:{"width":"0.7rem","height":"0.7rem"}}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.icon),expression:"item.icon"}],staticClass:"goDRiiBsuEuXD3W1NphN",staticStyle:{"width":"0.7rem","height":"0.7rem"}}),_c('span',{attrs:{"data-text":item.level}},[_vm._v(_vm._s(item.level))])])]),_c('van-col',{staticClass:"tr JIndut",attrs:{"span":"10"}},[_c('div',[_c('span',{staticClass:"RL9TJMMzDMOQihFhVnG2"},[_vm._v(" "+_vm._s(item.upwhere_bet))]),(_vm.formDelList.level+1==index)?_c('div',{staticClass:"_2lvGimPeAnoEFiUw_fTw zWM93XVBJEk4aHJfKHVR"},[_c('div',{staticClass:"ant-progress ant-progress-line ant-progress-status-normal ant-progress-default"},[_c('div',{staticClass:"ant-progress-outer"},[_c('div',{staticClass:"ant-progress-inner"},[_c('div',{staticClass:"ant-progress-bg",style:({
											width: `${(_vm.removeThousandSeparatorAndDecimalPoint(_vm.formDelList.now_bet)/_vm.removeThousandSeparatorAndDecimalPoint(item.upwhere_bet))*100}%`,
											height: '20px', 
											borderRadius:'100px',
											    'background-color': 'var(--theme-secondary-color-success)'
										})})])])]),_c('span',{staticClass:"reward-mixins__amount"},[_vm._v(_vm._s(_vm.formDelList.now_bet)+"/ "+_vm._s(item.upwhere_bet))])]):_vm._e()])]),_c('van-col',{staticClass:"tr",attrs:{"span":"10"}},[_c('span',{staticClass:"RL9TJMMzDMOQihFhVnG2 lJM4_qheVPhMhtExmFdS"},[_vm._v(_vm._s(item.upgrade_bonus))])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }